import { createSlice } from "@reduxjs/toolkit";
import { passengerManagement } from "../Actions/PassengerAction";
import { FleetOwnerManagement, fleetownerRequests } from "../Actions/FleetOwnerAction";
import { toast } from "react-toastify";


export const fleetownerslice = createSlice({
  name: "fleetownerslice",
  initialState: {
    alldata: "",
    loading: false,
    error: null,
    ownerRequests: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(FleetOwnerManagement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(FleetOwnerManagement.fulfilled, (state, action) => {
        state.alldata = action.payload.data.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(FleetOwnerManagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fleetownerRequests.fulfilled, (state, { payload }) => {
        if (payload?.status == 200) {
          state.ownerRequests = payload.data.data
          state.loading = false
          // toast.success(payload?.message)
        } else {
          toast.error(payload?.message)
          state.loading = false
        }
      })
      .addCase(fleetownerRequests.pending, (state, { payload }) => {
        state.loading = true
      })
      .addCase(fleetownerRequests.rejected, (state, { payload }) => {
        state.loading = false
        toast.error(payload?.message || "Something went wrong")
      })
  },
});

export default fleetownerslice.reducer;
