import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { passengerManagement } from "../Redux/Actions/PassengerAction";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import PaginationComponet from "../Components/Layout/Pagination";
import { VehicleManagemant } from "../Redux/Actions/VehiclesActions";

export default function VehicleRequest() {
  const vehicledata = useSelector((state) => state.vehicle?.alldata);
  console.log(vehicledata,"blhiwegtbfuweohgnweinjgwgnwoigwo");

  const [activeToggle, setActiveToggle] = useState("images");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);

  const [flag, setflag] = useState(true);
  const [type, settype] = useState("0");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      VehicleManagemant({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
      type:type
    };
    dispatch(VehicleManagemant(searchData));
  }, [page, limit, searchValue, flag, dispatch]);


  const handlenavigate = (id) => {
    navigate(`/vehicle-details/${id}`)
  }

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>New Vehicle Request</h2>
            </div>
            <div className="search-tab ">
              <div className="input-search ">
                <Form>
                  <div className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      placeholder="Search "
                      name="searchValue"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
              {/* <div className="filter-btn">
                <button>Filter</button>
              </div> */}
            </div>
            <div className="user-search-passengers">
              <div className="drop-down">
                {/* <div className="toggle-box-top">
              
                  <div
                    className={`toggle-btn ${
                      activeToggle === "images" ? "toggle-active" : ""
                    }`}
                    onClick={() => {setActiveToggle("images"); settype("0")}} 
                  >
                    <p>Active</p>
                  </div>

    
                  <div
                    className={`toggle-btn ${
                      activeToggle === "heicToJpg" ? "toggle-active" : ""
                    }`}
                    onClick={() => {setActiveToggle("heicToJpg"); settype(1)}} 
                  >
                    <p>Blocked</p>
                  </div>
                </div> */}
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Registration Number</th>
                    <th>Vehicle</th>
                    <th>Model</th>
                    <th>Registered in</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicledata?.cars?.map((res, index) => {
                    const serialNumber = (currentPage - 1) * limit + index + 1;     

                    const imageUrl = 
                    res?.profile_image && 
                    res.profile_image !== "null" && 
                    res.profile_image !== "undefined" && 
                    res.profile_image.trim() !== ""
                        ? `${url}${res.profile_image}`
                        : Logo;
                
                    return (
                      <tr key={index}>
                        <td>{serialNumber}</td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                           
                              <div className="user-id">
                                <p>
                                 {res?.reg_number || "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {res?.manufacturer || "N/A"}
                        </td>

                        <td>
                          <div className="user-id">
                          {res?.model || "N/A"}

                          </div>
                        </td>
                        <td>
                          <div className="user-id">
                            <p>
                            {res?.reg_in || "N/A"}

                            </p>
                          </div>
                        </td>
                       

                        <td>
                          <div className="user-view"  onClick={() => handlenavigate(res?._id)}>
                            <img
                              src={
                                require("../Assets/Images/view-icon.svg")
                                  .default
                              }
                             
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (currentPage - 1) * limit} -{" "}
                    {Math.min(currentPage * limit, vehicledata.totalCount)} of{" "}
                    {vehicledata.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={vehicledata?.totalCount}
                    limit={vehicledata?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
