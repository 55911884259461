import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";

export default function RidePrices() {
  return (
    <Layout>
      <Container fluid>
        <div className="user-search">
          <div className="edit-profile-page">
            <div className="edit-flex-text">
              <div className="main-heading">
                <h2>Set Basic price</h2>
              </div>
            </div>

            <Form>
              <div className="edit-input mt-4">
                <div className="input-select">
                  <Form.Select aria-label="Default select example">
                    <option> Select distance </option>
                    <option value="1">24</option>
                    <option value="2">25</option>
                    <option value="3">26</option>
                  </Form.Select>
                </div>

                <div className="user-info mt-4">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="type" placeholder="Base price" />
                  </Form.Group>
                </div>

                <button variant="primary" type="submit" className="submit">
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
