import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import {
  BlockActiveDriver,
  getRequestdriverDetails,
} from "../Redux/Actions/DriverActions";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import { FaFilePdf } from "react-icons/fa6";
import { toast } from "react-toastify";
import ConfirmModal from "../modals/ConfirmModal";

export default function VehiclesView() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [Requestdriverdata, setrequestdriverdata] = useState({});
  console.log(Requestdriverdata);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [SelectedType, setSelectedType] = useState("");

  const [choosenType, setChoosenType] = useState("");

  const [confirmModal, setConfirmModal] = useState(false);

  const handleConfirmModalClose = () => {
    setConfirmModal(false);
    setChoosenType("");
  };

  const handleConfirmModalOpen = (type) => {
    setConfirmModal(true);
    setChoosenType(type);
  };

  useEffect(() => {
    dispatch(getRequestdriverDetails({ userId: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setrequestdriverdata(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, confirmModal]);

  return (
    <Layout>
      <Container fluid>
        <div className=" driver-filter">
          <div className="inner-filter-field ">
            <div className="driver-page-bg driver-new-req-det">
              <div className="driver-prof">
                <div className="driver-info">
                  <div className="profile-image">
                    <h2>XYZ123</h2>
                  </div>
                  <div className="driver-add">
                    <p>
                      <span> Car manufacturer :- </span> XYZ123
                    </p>
                    <p>
                      <span> Added on :- </span> 11 sep 2023
                    </p>
                    <p>
                      <span>Registered in :-</span> 2019
                    </p>
                    <p className="ad-edit-pro">
                      <span> Price per km :- </span> $10/km
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M9.17656 5.58629L8.35161 4.76133L2.91862 10.1943V11.0193H3.74358L9.17656 5.58629ZM10.0015 4.76133L10.8265 3.93638L10.0015 3.11141L9.17656 3.93638L10.0015 4.76133ZM4.22683 12.186H1.75195V9.71106L9.58904 1.87398C9.81689 1.64617 10.1862 1.64617 10.414 1.87398L12.0639 3.5239C12.2917 3.7517 12.2917 4.12105 12.0639 4.34885L4.22683 12.186Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="approve-btn">
                  {Requestdriverdata?.is_active == "1" ? (
                    <button
                      onClick={() => handleConfirmModalOpen("blockDriver")}
                    >
                      Deactivate Vehicle
                    </button>
                  ) : (
                    <button
                      onClick={() => handleConfirmModalOpen("activeDriver")}
                    >
                      Deactivate Vehicle
                    </button>
                  )}
                  <button
                    onClick={() => handleConfirmModalOpen("deleteDriver")}
                  >
                    Remove Vehicle
                  </button>
                </div>
              </div>
              <div className="documents-add">
                <h2>Documents</h2>
              </div>
              <div className="driver-documents-grid">
                <div className="documents-count">
                  {Requestdriverdata?.driving_license_front ? (
                    Requestdriverdata?.driving_license_front.endsWith(
                      ".pdf"
                    ) ? (
                      <a
                        href={`${url}${Requestdriverdata?.driving_license_front}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFilePdf size={140} color="red" />
                      </a>
                    ) : Requestdriverdata?.driving_license_front.endsWith(
                        ".png"
                      ) ||
                      Requestdriverdata?.driving_license_front.endsWith(
                        ".jpg"
                      ) ||
                      Requestdriverdata?.driving_license_front.endsWith(
                        ".jpeg"
                      ) ? (
                      <img
                        src={`${url}${Requestdriverdata?.driving_license_front}`}
                        alt="Driving License Front"
                      />
                    ) : (
                      <p>Unsupported file format</p>
                    )
                  ) : (
                    <p>Document not found</p>
                  )}
                  <p>Document 1</p>
                </div>
                <div className="documents-count">
                  {Requestdriverdata?.id_front ? (
                    Requestdriverdata?.id_front.endsWith(".pdf") ? (
                      <a
                        href={`${url}${Requestdriverdata?.id_front}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFilePdf size={140} color="red" />
                      </a>
                    ) : Requestdriverdata?.id_front.endsWith(".png") ||
                      Requestdriverdata?.id_front.endsWith(".jpg") ||
                      Requestdriverdata?.id_front.endsWith(".jpeg") ? (
                      <img
                        src={`${url}${Requestdriverdata?.id_front}`}
                        alt="Driving License Front"
                      />
                    ) : (
                      <p>Unsupported file format</p>
                    )
                  ) : (
                    <p>Document not found</p>
                  )}
                  <p>Document 2</p>
                </div>
                <div className="documents-count ad-edit-documents">
                  {Requestdriverdata?.transport_license_front ? (
                    Requestdriverdata?.transport_license_front.endsWith(
                      ".pdf"
                    ) ? (
                      <a
                        href={`${url}${Requestdriverdata?.transport_license_front}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFilePdf size={140} color="red" />
                      </a>
                    ) : Requestdriverdata?.transport_license_front.endsWith(
                        ".png"
                      ) ||
                      Requestdriverdata?.transport_license_front.endsWith(
                        ".jpg"
                      ) ||
                      Requestdriverdata?.transport_license_front.endsWith(
                        ".jpeg"
                      ) ? (
                      <img
                        src={`${url}${Requestdriverdata?.transport_license_front}`}
                        alt="Driving License Front"
                      />
                    ) : (
                      <p>Unsupported file format</p>
                    )
                  ) : (
                    <p>Document not found</p>
                  )}
                  <p>Document 3</p>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M9.17656 5.58629L8.35161 4.76133L2.91862 10.1943V11.0193H3.74358L9.17656 5.58629ZM10.0015 4.76133L10.8265 3.93638L10.0015 3.11141L9.17656 3.93638L10.0015 4.76133ZM4.22683 12.186H1.75195V9.71106L9.58904 1.87398C9.81689 1.64617 10.1862 1.64617 10.414 1.87398L12.0639 3.5239C12.2917 3.7517 12.2917 4.12105 12.0639 4.34885L4.22683 12.186Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="car-driver-ad-v">
              <div className="car-driving-box w-100">
                <h2>Currently assigned</h2>
                <div className="car-name">
                  <div className="car-owner">
                    <img
                      src={require("../Assets/Images/driver-prof.svg").default}
                    />
                    <div className="vehicle-card-content">
                      <h2>John </h2>
                      <div>
                        <div className="rating user-id currently-assigned-rating">
                          <p>4.5</p>
                          <div>
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                            <img
                              src={
                                require("../Assets/Images/star-fill.svg")
                                  .default
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="car-option">
                    <img
                      src={require("../Assets/Images/car-option.svg").default}
                    />
                  </div>
                </div>
              </div>
              <div className="earnings-text py-4">
                <h2>Total earnings</h2>
                <p>$500</p>
              </div>
            </div>

            <div className="table-trips">
              <div className="image-heading-text">
                <h2>Trips</h2>
              </div>
              <div className="search-tab ">
                <div className="input-search ">
                  <Form>
                    <div className="search-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M19 19L13 13"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <circle
                          cx="8"
                          cy="8"
                          r="7"
                          stroke="#2F2B3D"
                          stroke-opacity="0.9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search " />
                    </Form.Group>
                  </Form>
                </div>
              </div>

              <div class="table-responsive">
                <Table size="sm" className="table-cmn">
                  <thead>
                    <tr>
                      <th>S.no.</th>
                      <th>Date & time</th>
                      <th>Driver name</th>
                      <th>Pickup & Dropoff</th>
                      <th>Payment mode</th>
                      <th>Total earning</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="first-user">
                          <div class="user-profile">
                            <img src="https://vibeon-api.bosselt.com/public/1737089152136--d66b47e5-d98b-4be4-aeed-f504967946b4.jpg" />
                            <div class="user-id">
                              <p>Roy Smith</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Cash</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="first-user">
                          <div class="user-profile">
                            <img src="https://vibeon-api.bosselt.com/public/1737089152136--d66b47e5-d98b-4be4-aeed-f504967946b4.jpg" />
                            <div class="user-id">
                              <p>Roy Smith</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Cash</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="first-user">
                          <div class="user-profile">
                            <img src="https://vibeon-api.bosselt.com/public/1737089152136--d66b47e5-d98b-4be4-aeed-f504967946b4.jpg" />
                            <div class="user-id">
                              <p>Roy Smith</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Online</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="first-user">
                          <div class="user-profile">
                            <img src="https://vibeon-api.bosselt.com/public/1737089152136--d66b47e5-d98b-4be4-aeed-f504967946b4.jpg" />
                            <div class="user-id">
                              <p>Roy Smith</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Online</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="first-user">
                          <div class="user-profile">
                            <img src="https://vibeon-api.bosselt.com/public/1737089152136--d66b47e5-d98b-4be4-aeed-f504967946b4.jpg" />
                            <div class="user-id">
                              <p>Roy Smith</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Cash</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>01</td>
                      <td>
                        <div className="first-user">
                          <div className="user-id">
                            <p>11 sep 2024, 05:00PM</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="first-user">
                          <div class="user-profile">
                            <img src="https://vibeon-api.bosselt.com/public/1737089152136--d66b47e5-d98b-4be4-aeed-f504967946b4.jpg" />
                            <div class="user-id">
                              <p>Roy Smith</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-id locaton">
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/pickup-locaton.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                          <p>
                            <span>
                              <img
                                src={
                                  require("../Assets/Images/dropoff-locatin.svg")
                                    .default
                                }
                              />
                            </span>
                            Pickup location
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className=" user-id">
                          <p>Cash</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-id">
                          <p>$500</p>
                        </div>
                      </td>
                      <td>
                        <div className="user-view">
                          <img
                            src={
                              require("../Assets/Images/view-icon.svg").default
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        <ConfirmModal
          handleConfirmModalClose={handleConfirmModalClose}
          confirmModal={confirmModal}
          choosenType={choosenType}
          idVal={id}
          data={Requestdriverdata?.is_active}
        />
      </Container>
    </Layout>
  );
}
