import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";

// ====== For FleetOwner Management =====

export const FleetOwnerManagement = createAsyncThunk(
  "FleetOwnerManagement",
  async (details) => {
    let url = `/fleetManagement?page=${details?.page}&limit=${details?.limit}&type=${details?.type}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);


// ==============  fleetowner Requests  =============

export const fleetownerRequests = createAsyncThunk(
  "fleetownerRequests",
  async (details) => {
    let url = `/newfleetRequests?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);


// ======= get fleet by id  ==========

export const GetFleetRequestdetails = createAsyncThunk(
  "GetFleetRequestdetails",
  async (details) => {
    const data = await AdminAPI.get(`/getFleetById?fleetId=${details?.fleetId}`);
    console.log(data);
    return data;
  }
);



// ===========   Accept or Decline fleet request ===========


export const AcceptDeclineFleetRequest = createAsyncThunk(
  "AcceptDeclineFleetRequest",
  async (details) => {
    const data = await AdminAPI.patch(`/verifyFleet?fleetId`, details);
    console.log(data);
    return data;
  }
);
