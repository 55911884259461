import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";

// ====== For Vehiclemanagement =====

export const VehicleManagemant = createAsyncThunk(
  "VehicleManagemant",
  async (details) => {
    let url = `/vehicleManagement?page=${details?.page}&limit=${details?.limit}&type=${details?.type}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);


// ============== Get vehicle By id ============

export const getvehicledetails = createAsyncThunk(
  "getvehicledetails",
  async (details) => {
    const data = await AdminAPI.get(`/carDetails?vehicleId=${details?.vehicleId}`);
    console.log(data);
    return data;
  }
);


// ==============  Approve Decline Vehicle ============

export const ApproveDeclineVehicle = createAsyncThunk(
  "ApproveDeclineVehicle",
  async (details) => {
    const data = await AdminAPI.put(`/verifyCar`, details);
    console.log(data);
    return data;
  }
);
export const AddVehiclePrice = createAsyncThunk(
  "AddVehiclePrice",
  async (details) => {
    const data = await AdminAPI.put(`/setPrice`, details);
    console.log(data);
    return data;
  }
);
