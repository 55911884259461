import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { ActiveDriverlist } from "../Redux/Actions/DriverActions";
import { useDispatch } from "react-redux";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import PaginationComponet from "../Components/Layout/Pagination";

export default function TotalDrivers() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setpage] = useState(1);
  const [type, settype] = useState("0");
  const [limit, setlimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [Driverslisting, setactivedriverlisting] = useState([]);
  const [userType, setUserType] = useState("1");

  useEffect(() => {
    dispatch(ActiveDriverlist({ page, limit, searchValue, type }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setactivedriverlisting(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [limit, page, searchValue, type]);

  const handlenavigate = (id) => {
    navigate(`/active-driver-details/${id}`);
  };

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber);
    dispatch(ActiveDriverlist({ page, limit, searchValue, userType }));
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Drivers (5)</h2>
            </div>
            <div className="search-tab ">
              <div className="input-search ">
                <Form>
                  <div className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      placeholder="Search "
                      name="searchValue"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Driver name</th>
                    <th>Email</th>
                    <th>Phone no.</th>
                    <th>Rating</th>
                    <th>Total earning</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Driverslisting &&
                    Driverslisting?.drivers?.map((res, index) => {
                      const serialNumber = (page - 1) * limit + index + 1;
                      return (
                        <tr key={index}>
                          <td>{serialNumber}</td>
                          <td>
                            <div className="first-user">
                              <div className="user-profile">
                                <img
                                  src={
                                    res?.profile_image
                                      ? `${url}${res?.profile_image}`
                                      : Logo
                                  }
                                />
                                <div className="user-id">
                                  <p>
                                    {res?.first_name && res?.last_name
                                      ? `${res?.first_name} ${res?.last_name}`
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="user-id">
                              <p> {res?.email || "N/A"}</p>
                            </div>
                          </td>
                          <td>
                            <div className="user-id">
                              <p>
                                {" "}
                                {res?.country_code && res?.phone_number
                                  ? `${res?.country_code} ${res?.phone_number}`
                                  : "N/A"}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="rating user-id">
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <img
                                src={
                                  require("../Assets/Images/star-fill.svg")
                                    .default
                                }
                              />
                              <p>0.2</p>
                            </div>
                          </td>
                          <td>
                            <div className="user-id">
                              <p>$500</p>
                            </div>
                          </td>
                          <td>
                            <div className="user-view">
                              <img
                                src={
                                  require("../Assets/Images/view-icon.svg")
                                    .default
                                }
                                onClick={() => handlenavigate(res?._id)}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                  {/* <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="rating user-id">
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <p>0.2</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>$500</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="rating user-id">
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <p>0.2</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>$500</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="rating user-id">
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <p>0.2</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>$500</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="rating user-id">
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <p>0.2</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>$500</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>brenda123@gmail.com</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>+91 98765 45321</p>
                      </div>
                    </td>
                    <td>
                      <div className="rating user-id">
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-fill.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <img
                          src={
                            require("../Assets/Images/star-empty.svg").default
                          }
                        />
                        <p>0.2</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-id">
                        <p>$500</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    Showing {1 + (page - 1) * limit} -{" "}
                    {Math.min(page * limit, Driverslisting.totalCount)} of{" "}
                    {Driverslisting.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={Driverslisting.totalCount}
                    limit={Driverslisting.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
