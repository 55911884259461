import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import { FaFilePdf } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import {
  AcceptDeclineFleetRequest,
  GetFleetRequestdetails,
} from "../Redux/Actions/FleetOwnerAction";
import { formatNumber } from "../Utils/commonFiles";

export default function FleetNewRequestsDetail() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [fleetRequestData, setFleetRequestData] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [SelectedType, setSelectedType] = useState("");

  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(GetFleetRequestdetails({ fleetId: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setFleetRequestData(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const ActionHandler = (type) => {
    if (type == 1) {
      dispatch(AcceptDeclineFleetRequest({ fleetId: id, type: type }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setShow(false);
            navigate("/fleet-owner-request");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(AcceptDeclineFleetRequest({ fleetId: id, type: type }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setShow(false);
            navigate("/fleet-owner-request");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const ActionHandlerHandleOpen = (type) => {
    if (type == 1) {
      setModalContent("Are you sure you want to Accept this request?");
      setShow(true);
      setSelectedType(1);
    } else {
      setShow(true);
      setModalContent("Are you sure you want to Decline this request?");
      setSelectedType(2);
    }
  };

  console.log(fleetRequestData?.vehicles?.length, 'fleetRequestData?.vehicles?.length < 10');


  return (
    <Layout>
      <Container fluid className="fleet-owner-request">
        <div className="filters">
          <div className=" driver-new-req-det">
            <div className="driver-prof">
              <div className="driver-info">
                <div className="profile-image">
                  <img
                    src={
                      fleetRequestData?.profile_image
                        ? `${url}${fleetRequestData?.profile_image}`
                        : Logo
                    }
                  />
                  <h2>
                    {fleetRequestData?.company_name
                      ? `${fleetRequestData?.company_name}`
                      : "N/A"}
                  </h2>
                </div>
                <div className="driver-add">
                  <p>
                    <span> Email id :- </span>{" "}
                    {fleetRequestData?.company_email || "N/A"}
                  </p>
                  <p>
                    <span> Phone no. :- </span>{" "}
                    {fleetRequestData?.country_code &&
                      fleetRequestData?.phone_number
                      ? `${fleetRequestData.country_code} ${fleetRequestData.phone_number}`
                      : fleetRequestData?.country_code
                        ? fleetRequestData.country_code
                        : fleetRequestData?.phone_number
                          ? fleetRequestData.phone_number
                          : "N/A"}
                  </p>
                </div>
              </div>
              <div className="approve-btn">
                <button onClick={() => ActionHandlerHandleOpen(1)}>
                  Accept
                </button>
                <button onClick={() => ActionHandlerHandleOpen(2)}>
                  Decline
                </button>
              </div>
            </div>
            <div className="documents-add">
              <h2>Documents</h2>
            </div>
            <div className="driver-documents-grid">
              <div className="documents-count">
                {fleetRequestData?.business_reg ? (
                  fleetRequestData?.business_reg.endsWith(".pdf") ? (
                    <a
                      href={`${url}${fleetRequestData?.business_reg}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : fleetRequestData?.business_reg.endsWith(".png") ||
                    fleetRequestData?.business_reg.endsWith(".jpg") ||
                    fleetRequestData?.business_reg.endsWith(".jpeg") ? (
                    <img
                      src={`${url}${fleetRequestData?.business_reg}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>Bussiness Registaration</p>
              </div>
              <div className="documents-count">
                {fleetRequestData?.vat ? (
                  fleetRequestData?.vat.endsWith(".pdf") ? (
                    <a
                      href={`${url}${fleetRequestData?.vat}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : fleetRequestData?.vat.endsWith(".png") ||
                    fleetRequestData?.vat.endsWith(".jpg") ||
                    fleetRequestData?.vat.endsWith(".jpeg") ? (
                    <img
                      src={`${url}${fleetRequestData?.vat}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>VAT Identification Number</p>
              </div>
            </div>
          </div>
        </div>
        <div className="driver-info new-deriver-info-box">
          <div className="profile-image">
            <h2>Bank details</h2>
          </div>
          <div className="driver-add">
            <p>
              <span> Bank account number :- </span>{" "}
              {fleetRequestData?.account_number || "N/A"}
            </p>
            <p>
              <span> Name of the bank :- </span>{" "}
              {fleetRequestData?.bank_name || "N/A"}
            </p>

            <p>
              <span> IBAN no. :- </span>{" "}
              {fleetRequestData?.iban_number || "N/A"}
            </p>
            <p>
              <span> BIC no. :- </span> {fleetRequestData?.bic_number || "N/A"}
            </p>
          </div>
        </div>

        <div className="new-deriver-info-box">
          <div className="total-vehicles-req">
            <h3>Total vehicles</h3>
            <p>
              {formatNumber(fleetRequestData?.vehicles?.length)}{" "}
              <span>
                {fleetRequestData?.vehicles?.length > 0 ? <img
                  src={require("../Assets/Images/view-icon.svg").default}
                  onClick={() => navigate(`/set-vehicles-price/${id}`)}
                /> : null}
              </span>
            </p>
          </div>
        </div>

        <div className="filters">
          <div className=" driver-new-req-det">
            <div className="driver-prof">
              <div className="driver-info">
                <div className="profile-image">
                  <h2>
                    {fleetRequestData?.company_name
                      ? `${fleetRequestData?.company_name}`
                      : "N/A"}
                  </h2>
                </div>
                <div className="driver-add">
                  <p>
                    <span> Car manufacturer :- </span> Volvo ABC
                  </p>
                  <p>
                    <span> Added on :- </span> 11 sep 2023
                  </p>
                  <p>
                    <span> Registered in :- </span> 2019
                  </p>
                </div>
              </div>
            </div>
            <div className="documents-add">
              <h2>Documents</h2>
            </div>
            <div className="driver-documents-grid">
              <div className="documents-count">
                {fleetRequestData?.business_reg ? (
                  fleetRequestData?.business_reg.endsWith(".pdf") ? (
                    <a
                      href={`${url}${fleetRequestData?.business_reg}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : fleetRequestData?.business_reg.endsWith(".png") ||
                    fleetRequestData?.business_reg.endsWith(".jpg") ||
                    fleetRequestData?.business_reg.endsWith(".jpeg") ? (
                    <img
                      src={`${url}${fleetRequestData?.business_reg}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>Bussiness Registaration</p>
              </div>
              <div className="documents-count">
                {fleetRequestData?.vat ? (
                  fleetRequestData?.vat.endsWith(".pdf") ? (
                    <a
                      href={`${url}${fleetRequestData?.vat}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : fleetRequestData?.vat.endsWith(".png") ||
                    fleetRequestData?.vat.endsWith(".jpg") ||
                    fleetRequestData?.vat.endsWith(".jpeg") ? (
                    <img
                      src={`${url}${fleetRequestData?.vat}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>VAT Identification Number</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button
            className="new-secondary"
            variant="secondary"
            onClick={handleClose}
          >
            No
          </Button>

          {SelectedType === 2 ? (
            <Button
              className="new-danger"
              variant="danger"
              onClick={() => ActionHandler(2)}
            >
              Yes
            </Button>
          ) : (
            <Button
              className="new-danger"
              variant="danger"
              onClick={() => ActionHandler(1)}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
