import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function Earning() {
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="earnings-check">
          <div className="earnings-boxs first-earnings-boxs">
            <p className="d-flex gap-2">Platform fees</p>
            <h2>
              $10<span>/vechicle </span>
              <Link to="/ride-prices">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="14.5"
                    cy="14.5"
                    r="14"
                    fill="white"
                    stroke="#21387E"
                  />
                  <path
                    d="M17.6545 12.4494L16.4584 11.2532L8.58052 19.1311V20.3273H9.77671L17.6545 12.4494ZM18.8507 11.2532L20.0469 10.0571L18.8507 8.86087L17.6545 10.0571L18.8507 11.2532ZM10.4774 22.019H6.88885V18.4303L18.2526 7.06658C18.583 6.73627 19.1185 6.73627 19.4488 7.06658L21.8412 9.45896C22.1715 9.78928 22.1715 10.3248 21.8412 10.6552L10.4774 22.019Z"
                    fill="#21387E"
                  />
                </svg>
              </Link>
            </h2>
          </div>
          <div className="earnings-boxs">
            <p>Total earning</p>
            <h2>
              $500<span>/month</span>
            </h2>
          </div>
        </div>
        <div className="filters ">
          <div className="inner-filter-field mb-5">
            <div className="image-heading-text">
              <h2>Active Fleet Owner</h2>
            </div>
            <div className="search-tab ">
              <div className="input-search ">
                <Form>
                  <div className="search-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M19 19L13 13"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#2F2B3D"
                        stroke-opacity="0.9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control type="text" placeholder="Search " />
                  </Form.Group>
                </Form>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Active vehicles</th>
                    <th>Platform fee (per month)</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>james123@gmail.com</td>
                    <td>
                      <div className="user-id">
                        <p>2</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>$20</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>james123@gmail.com</td>
                    <td>
                      <div className="user-id">
                        <p>2</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>$20</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>james123@gmail.com</td>
                    <td>
                      <div className="user-id">
                        <p>2</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>$20</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>james123@gmail.com</td>
                    <td>
                      <div className="user-id">
                        <p>2</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>$20</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>01</td>
                    <td>
                      <div className="first-user">
                        <div className="user-profile">
                          <img
                            src={require("../Assets/Images/user.svg").default}
                          />
                          <div className="user-id">
                            <p>James</p>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>james123@gmail.com</td>
                    <td>
                      <div className="user-id">
                        <p>2</p>
                      </div>
                    </td>

                    <td>
                      <div className="user-id">
                        <p>$20</p>
                      </div>
                    </td>
                    <td>
                      <div className="user-view">
                        <img
                          src={
                            require("../Assets/Images/view-icon.svg").default
                          }
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
