import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import {
  DriverApproveDecline,
  getRequestdriverDetails,
} from "../Redux/Actions/DriverActions";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import { FaFilePdf } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import ImagePreviewModal from "../modals/ImagePreviewModal";

export default function DriversNewRequestsDetail() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [Requestdriverdata, setrequestdriverdata] = useState({});
  console.log(Requestdriverdata);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [SelectedType, setSelectedType] = useState("");

  const [imageModal, setImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handlePrivewImagesOpen = (img) => {
    setImageModal(true);
    setSelectedImage(img);
  };

  const handlePrivewImagesClose = () => {
    setImageModal(false);
    setSelectedImage("");
  };

  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(getRequestdriverDetails({ userId: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setrequestdriverdata(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const ActionHandler = (type) => {
    if (type == 1) {
      dispatch(DriverApproveDecline({ userId: id, type: type }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setShow(false);
            navigate("/driver");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(DriverApproveDecline({ userId: id, type: type }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setShow(false);
            navigate("/driver");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const ActionHandlerHandleOpen = (type) => {
    if (type == 1) {
      setModalContent("Are you sure you want to approve this request?");
      setShow(true);
      setSelectedType(1);
    } else {
      setShow(true);
      setModalContent("Are you sure you want to decline this request?");
      setSelectedType(2);
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field driver-new-req-det">
            <div className="driver-prof">
              <div className="driver-info">
                <div className="profile-image">
                  <img
                    src={
                      Requestdriverdata?.profile_image
                        ? `${url}${Requestdriverdata?.profile_image}`
                        : Logo
                    }
                  />
                  <h2>
                    {Requestdriverdata?.first_name &&
                    Requestdriverdata?.last_name
                      ? `${Requestdriverdata?.first_name} ${Requestdriverdata?.last_name}`
                      : "N/A"}
                  </h2>
                </div>
                <div className="driver-add">
                  <p>
                    <span> Email id :- </span>{" "}
                    {Requestdriverdata?.email || "N/A"}
                  </p>
                  <p>
                    <span> Phone no. :- </span>{" "}
                    {Requestdriverdata?.country_code &&
                    Requestdriverdata?.phone_number
                      ? `${Requestdriverdata?.country_code} ${Requestdriverdata?.phone_number}`
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="approve-btn">
                <button onClick={() => ActionHandlerHandleOpen(1)}>
                  Approve
                </button>
                <button onClick={() => ActionHandlerHandleOpen(2)}>
                  Decline
                </button>
              </div>
            </div>
            <div className="documents-add">
              <h2>Documents</h2>
            </div>
            <div className="driver-documents-grid">
              <div className="documents-count">
                {Requestdriverdata?.driving_license_front ? (
                  Requestdriverdata?.driving_license_front.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Requestdriverdata?.driving_license_front}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : Requestdriverdata?.driving_license_front.endsWith(
                      ".png"
                    ) ||
                    Requestdriverdata?.driving_license_front.endsWith(".jpg") ||
                    Requestdriverdata?.driving_license_front.endsWith(
                      ".jpeg"
                    ) ? (
                    <img
                      onClick={() =>
                        handlePrivewImagesOpen(
                          Requestdriverdata?.driving_license_front
                        )
                      }
                      src={`${url}${Requestdriverdata?.driving_license_front}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>Licence Front</p>
              </div>
              <div className="documents-count">
                {Requestdriverdata?.id_front ? (
                  Requestdriverdata?.id_front.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Requestdriverdata?.id_front}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : Requestdriverdata?.id_front.endsWith(".png") ||
                    Requestdriverdata?.id_front.endsWith(".jpg") ||
                    Requestdriverdata?.id_front.endsWith(".jpeg") ? (
                    <img
                      onClick={() =>
                        handlePrivewImagesOpen(Requestdriverdata?.id_front)
                      }
                      src={`${url}${Requestdriverdata?.id_front}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>Id Front</p>
              </div>
              <div className="documents-count">
                {Requestdriverdata?.transport_license_front ? (
                  Requestdriverdata?.transport_license_front.endsWith(
                    ".pdf"
                  ) ? (
                    <a
                      href={`${url}${Requestdriverdata?.transport_license_front}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : Requestdriverdata?.transport_license_front.endsWith(
                      ".png"
                    ) ||
                    Requestdriverdata?.transport_license_front.endsWith(
                      ".jpg"
                    ) ||
                    Requestdriverdata?.transport_license_front.endsWith(
                      ".jpeg"
                    ) ? (
                    <img
                      onClick={() =>
                        handlePrivewImagesOpen(
                          Requestdriverdata?.transport_license_front
                        )
                      }
                      src={`${url}${Requestdriverdata?.transport_license_front}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>Transport Licence Front</p>
              </div>
              <div className="documents-count">
                {Requestdriverdata?.driving_license_back ? (
                  Requestdriverdata?.driving_license_back.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Requestdriverdata?.driving_license_back}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : Requestdriverdata?.driving_license_back.endsWith(
                      ".png"
                    ) ||
                    Requestdriverdata?.driving_license_back.endsWith(".jpg") ||
                    Requestdriverdata?.driving_license_back.endsWith(
                      ".jpeg"
                    ) ? (
                    <img
                      onClick={() =>
                        handlePrivewImagesOpen(
                          Requestdriverdata?.driving_license_back
                        )
                      }
                      src={`${url}${Requestdriverdata?.driving_license_back}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>Licence Back</p>
              </div>
              <div className="documents-count">
                {Requestdriverdata?.id_back ? (
                  Requestdriverdata?.id_back.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Requestdriverdata?.id_back}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={100} color="red" />
                    </a>
                  ) : Requestdriverdata?.id_back.endsWith(".png") ||
                    Requestdriverdata?.id_back.endsWith(".jpg") ||
                    Requestdriverdata?.id_back.endsWith(".jpeg") ? (
                    <img
                      onClick={() =>
                        handlePrivewImagesOpen(Requestdriverdata?.id_back)
                      }
                      src={`${url}${Requestdriverdata?.id_back}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>Id Back</p>
              </div>
              <div className="documents-count">
                {Requestdriverdata?.transport_license_back ? (
                  Requestdriverdata?.transport_license_back.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Requestdriverdata?.transport_license_back}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={100} color="red" />
                    </a>
                  ) : Requestdriverdata?.transport_license_back.endsWith(
                      ".png"
                    ) ||
                    Requestdriverdata?.transport_license_back.endsWith(
                      ".jpg"
                    ) ||
                    Requestdriverdata?.transport_license_back.endsWith(
                      ".jpeg"
                    ) ? (
                    <img
                      onClick={() =>
                        handlePrivewImagesOpen(
                          Requestdriverdata?.transport_license_back
                        )
                      }
                      src={`${url}${Requestdriverdata?.transport_license_back}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}
                <p>Transport Licence Back</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button
            className="new-secondary"
            variant="secondary"
            onClick={handleClose}
          >
            No
          </Button>

          {SelectedType === 2 ? (
            <Button
              className="new-danger"
              variant="danger"
              onClick={() => ActionHandler(2)}
            >
              Yes
            </Button>
          ) : (
            <Button
              className="new-danger"
              variant="danger"
              onClick={() => ActionHandler(1)}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <ImagePreviewModal
        handlePrivewImagesClose={handlePrivewImagesClose}
        imageModal={imageModal}
        selectedImage={selectedImage}
      />
    </Layout>
  );
}
