import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";

const FleetForm = ({ fleetRequestData, handleSubmit }) => {
    const initialValues = {
        vehicles: fleetRequestData?.vehicles?.map((vehicle) => ({
            id: vehicle?._id,
            price: vehicle?.price || "",
            manufacturer: vehicle?.manufacturer,
            reg_in: vehicle?.reg_in,
            reg_number: vehicle?.reg_number,
        })) || [],
    };

    const validationSchema = Yup.object().shape({
        vehicles: Yup.array().of(
            Yup.object().shape({
                price: Yup.number()
                    .required("Price is required.")
                    .min(0, "Price cannot be negative."),
            })
        ),
    });

    const onSubmit = (values) => {
        handleSubmit(values.vehicles);
    };

    if (!fleetRequestData?.vehicles?.length) {
        return <div>Loading...</div>;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ values, handleChange }) => (
                <Form>
                    <Row>
                        {values.vehicles.map((vehicle, index) => (
                            <Col lg={6} key={vehicle.id}>
                                <div className="vehicles-info-pk">
                                    <div className="driver-add">
                                        <p>
                                            <span>Car :- </span> {vehicle?.manufacturer}
                                        </p>
                                        <p>
                                            <span>Registered in :- </span> {vehicle?.reg_in}
                                        </p>
                                        <p>
                                            <span>Registered no. :- </span> {vehicle?.reg_number}
                                        </p>
                                    </div>
                                    <div className="enter-price-field">
                                        <div className="user-info mt-2">
                                            <Field
                                                type="number"
                                                name={`vehicles[${index}].price`}
                                                className="form-control"
                                                placeholder="Enter price"
                                                onChange={handleChange}
                                                required
                                            />
                                            <ErrorMessage
                                                name={`vehicles[${index}].price`}
                                                component="div"
                                                className="text-danger"
                                            />
                                            <span>per kms</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                        <Col lg={12}>
                            <button type="submit" className="submit mt-4">
                                Continue
                            </button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
};

export default FleetForm;
