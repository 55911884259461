import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import {
  DriverApproveDecline,
  getRequestdriverDetails,
} from "../Redux/Actions/DriverActions";
import Logo from "../Assets/Images/VibeOnn_Placeholder.png";
import { FaFilePdf } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { ApproveDeclineVehicle, getvehicledetails } from "../Redux/Actions/VehiclesActions";
import { format } from "date-fns";
import ImagePreviewModal from "../modals/ImagePreviewModal";




export default function VehicleDetails() {
    const url = process.env.REACT_APP_FILE_BASE_URL;
    const [Vehicledata, setvehicledata] = useState({});
    console.log(Vehicledata);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [SelectedType, setSelectedType] = useState("");
    const [imageModal, setImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(""); 
    const handleClose = () => setShow(false);

    
    const handlePrivewImagesOpen = (img) => {
      setImageModal(true);
      setSelectedImage(img);
    };
  
    const handlePrivewImagesClose = () => {
      setImageModal(false);
      setSelectedImage("");
    };



    useEffect(() => {
      dispatch(getvehicledetails({ vehicleId: id }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            setvehicledata(res?.payload?.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, [id]);

    const ActionHandler = (type) => {
      if(type == 1) {
        dispatch(ApproveDeclineVehicle({carId:id, type:type})).then((res)=> {
          console.log(res);
          if (res?.payload?.data?.status === 200){
            toast.success(res?.payload?.data?.message);
            setShow(false)
            navigate("/vehicle-new-request")
          }
        }).catch((error) => {
          console.log(error);
        })
      } else {
        dispatch(ApproveDeclineVehicle({carId:id, type:type})).then((res)=> {
          console.log(res);
          if (res?.payload?.data?.status === 200){
            toast.success(res?.payload?.data?.message);
            setShow(false)
            navigate("/vehicle-new-request")

          }
        }).catch((error) => {
          console.log(error);
        })
      }

    }

    const ActionHandlerHandleOpen =(type)=>{
      if(type == 1) {
        setModalContent("Are you sure you want to approve this vehicle?");
        setShow(true)
        setSelectedType(1)
      } else {
        setShow(true)
        setModalContent("Are you sure you want to decline this vehicle?");
        setSelectedType(2)
      }
    }

    console.log(`${url}${Vehicledata?.insurance}`, "jkqfhboiqehiegjnoiwrgn");

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field driver-new-req-det">
            <div className="driver-prof">
              <div className="driver-info">
                <div className="profile-image">
                  <h2>{Vehicledata?.reg_number || "N/A"}</h2>
              
                </div>
                <div className="driver-add">
                  <p>
                    <span> Car manufacturer:- </span>{" "}
                    {Vehicledata?.manufacturer || "N/A"}
                  </p>
                  <p>
                    <span> Added on:- </span>{" "}
                    {Vehicledata?.createdAt
                        ? format(
                            new Date(Vehicledata.createdAt),
                            "dd MMM yyyy"
                          )
                        : "N/A"}
                  </p>
                  <p>
                    <span> Registered in:- </span>{" "}
                    {Vehicledata?.reg_in || "N/A"}
                  </p>
                </div>
              </div>
              <div className="approve-btn">
                <button onClick={() => ActionHandlerHandleOpen(1)}>Approve</button>
                <button onClick={() => ActionHandlerHandleOpen(2)}>Decline</button>
              </div>
            </div>
            <div className="documents-add">
              <h2>Documents</h2>
            </div>
            <div className="driver-documents-grid">
            <div className="documents-count">
              {Vehicledata?.insurance ? (
                  Vehicledata?.insurance.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Vehicledata?.insurance}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : Vehicledata?.insurance.endsWith(".png") ||
                    Vehicledata?.insurance.endsWith(".jpg") ||
                    Vehicledata?.insurance.endsWith(".JPG") ||
                    Vehicledata?.insurance.endsWith(".jpeg") ? (
                    <img
                    onClick={() =>
                      handlePrivewImagesOpen(
                        Vehicledata?.insurance
                      )
                    }
                      src={`${url}${Vehicledata?.insurance}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}

                <p>Document 1</p>
              </div>

              <div className="documents-count">
              {Vehicledata?.rental_concession ? (
                  Vehicledata?.rental_concession.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Vehicledata?.rental_concession}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : Vehicledata?.rental_concession.endsWith(".png") ||
                    Vehicledata?.rental_concession.endsWith(".jpg") ||
                    Vehicledata?.rental_concession.endsWith(".jpeg") ? (
                    <img
                    onClick={() =>
                      handlePrivewImagesOpen(
                        Vehicledata?.rental_concession
                      )
                    }
                      src={`${url}${Vehicledata?.rental_concession}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}

                <p>Document 2</p>
              </div>

              <div className="documents-count">
              {Vehicledata?.reg_doc ? (
                  Vehicledata?.reg_doc.endsWith(".pdf") ? (
                    <a
                      href={`${url}${Vehicledata?.reg_doc}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFilePdf size={140} color="red" />
                    </a>
                  ) : Vehicledata?.reg_doc.endsWith(".png") ||
                    Vehicledata?.reg_doc.endsWith(".jpg") ||
                    Vehicledata?.reg_doc.endsWith(".jpeg") ? (
                    <img
                    onClick={() =>
                      handlePrivewImagesOpen(
                        Vehicledata?.reg_doc
                      )
                    }
                      src={`${url}${Vehicledata?.reg_doc}`}
                      alt="Driving License Front"
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )
                ) : (
                  <p>Document not found</p>
                )}

                <p>Document 3</p>
              </div>

             
            </div>
          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button className="new-secondary" variant="secondary" onClick={handleClose}>
            No
          </Button>

          {SelectedType === 2 ?
          
           <Button className="new-danger" variant="danger" onClick={()=>ActionHandler(2)}>
           Yes
         </Button>:
          <Button className="new-danger" variant="danger" onClick={()=>ActionHandler(1)}>
          Yes
        </Button>
        }
         
        </Modal.Footer>
      </Modal>

      <ImagePreviewModal
          handlePrivewImagesClose={handlePrivewImagesClose}
          imageModal={imageModal}
          selectedImage={selectedImage}
        />
    </Layout>
  );
}
