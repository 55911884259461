import { createSlice } from "@reduxjs/toolkit";
import { AddVehiclePrice, VehicleManagemant } from "../Actions/VehiclesActions";
import { toast } from "react-toastify";


export const vehicleSlice = createSlice({
  name: "vehicleSlice",
  initialState: {
    alldata: "",
    loading: false,
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(VehicleManagemant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(VehicleManagemant.fulfilled, (state, action) => {

        state.alldata = action.payload.data.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(VehicleManagemant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      ///

      .addCase(AddVehiclePrice.pending, (state) => {
        state.loading = true;
      })
      .addCase(AddVehiclePrice.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(action?.payload?.data?.message);
      })
      .addCase(AddVehiclePrice.rejected, (state, action) => {
        state.loading = false;
        toast.error(action?.payload?.data?.message);
      });
  },
});

export default vehicleSlice.reducer;
