import React, { useEffect, useState } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { Col, Row } from "react-bootstrap";
import { GetFleetRequestdetails } from "../Redux/Actions/FleetOwnerAction";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import FleetForm from "../Components/FleetForm";
import { AddVehiclePrice } from "../Redux/Actions/VehiclesActions";

export default function SetVehiclesPrice() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fleetRequestData, setFleetRequestData] = useState({});

  useEffect(() => {
    dispatch(GetFleetRequestdetails({ fleetId: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setFleetRequestData(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const handleSubmit = async (data) => {
    let filteredArray = data.map((res) => { return { vehicleId: res.id, price: res?.price } })
    const payload = {
      priceData: filteredArray
    }
    try {
      await dispatch(AddVehiclePrice(payload))
      navigate(-1)
    } catch (error) {

    }
  }

  return (
    <Layout>
      <Container fluid>
        <div className="set-prize-vehicles filters">
          <div className="vehicles-prize-heading">
            <h2>Set price for the vehicles </h2>
          </div>
          <div className="vehicles-full-disp">
            <FleetForm fleetRequestData={fleetRequestData} handleSubmit={handleSubmit} />
          </div>
        </div>
      </Container>
    </Layout>
  );
}
